export const environment = {
  production: true,
  apiUrl: "https://dev-api.cloud.cyclair.fr",
  sseRoute: "/api/v1/rovers/events",
  iam: {
    url: "https://dev-iam.cloud.cyclair.fr",
    realm: "cyclair",
    clientId: "control-panel"
  },
  faro: {
    url: 'https://faro-collector-prod-eu-west-2.grafana.net/collect/ad9b257eecfde6f4625bff5dc02345fa',
    app: {
      name: 'cloud-dev',
      version: '1.0.0',
      environment: 'production'
    },
  },
  defaultLocale: 'fr'
};
